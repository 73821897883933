<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-container fluid>
                    <div class="flex-container">
                        <v-card
                            outlined
                            width="320"
                            elevation="6"
                            color="orange"
                            class="mx-auto my-4"
                            @click="vendasDetalhes('day')"
                        >
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="overline mb-4">HOJE</div>
                                    <v-list-item-title class="headline mb-1">
                                        R$ {{ formatPrice(total.hoje) }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ periodo.hoje }}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-icon tile size="80">mdi-chart-areaspline</v-icon>
                            </v-list-item>

                            <v-card-actions>
                                <v-btn outlined rounded text>
                                    <v-icon dark>mdi-menu</v-icon>
                                </v-btn>
                                <v-btn
                                    outlined
                                    rounded
                                    text
                                    @click.stop="gerarRelatorio('day')"
                                    :loading="loading"
                                >
                                    <v-icon dark>mdi-printer</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card
                            outlined
                            width="320"
                            color="cyan"
                            elevation="6"
                            class="mx-auto my-4"
                            @click="vendasDetalhes('isoWeek')"
                        >
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="overline mb-4">ESSA SEMANA</div>
                                    <v-list-item-title class="headline mb-1">
                                        R$ {{ formatPrice(total.semana) }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ periodo.semana }}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-icon tile size="80">mdi-chart-areaspline</v-icon>
                            </v-list-item>

                            <v-card-actions>
                                <v-btn outlined rounded text>
                                    <v-icon dark>mdi-menu</v-icon>
                                </v-btn>
                                <v-btn
                                    outlined
                                    rounded
                                    text
                                    @click.stop="gerarRelatorio('week')"
                                    :loading="loading"
                                >
                                    <v-icon dark>mdi-printer</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card
                            outlined
                            width="320"
                            elevation="6"
                            color="#e766ca"
                            class="mx-auto my-4"
                            @click="vendasDetalhes('month')"
                        >
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="overline mb-4">ESSE MÊS</div>
                                    <v-list-item-title class="headline mb-1">
                                        R$ {{ formatPrice(total.mes) }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ periodo.mes }}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-icon tile size="80">mdi-chart-areaspline</v-icon>
                            </v-list-item>

                            <v-card-actions>
                                <v-btn outlined rounded text>
                                    <v-icon dark>mdi-menu</v-icon>
                                </v-btn>
                                <v-btn
                                    outlined
                                    rounded
                                    text
                                    @click.stop="gerarRelatorio('month')"
                                    :loading="loading"
                                >
                                    <v-icon dark>mdi-printer</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card
                            outlined
                            width="320"
                            elevation="6"
                            color="#01FF70"
                            class="mx-auto my-4"
                            @click="vendasDetalhes('year')"
                        >
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="overline mb-4">ESSE ANO</div>
                                    <v-list-item-title class="headline mb-1">
                                        R$ {{ formatPrice(total.ano) }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ new Date().getFullYear() }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                                <v-icon tile size="80">mdi-chart-areaspline</v-icon>
                            </v-list-item>

                            <v-card-actions>
                                <v-btn outlined rounded text>
                                    <v-icon dark>mdi-menu</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                        <v-card
                            class="mx-auto my-4"
                            width="320"
                            min-height="70"
                            elevation="6"
                            outlined
                        >
                            <canvas id="chart" width="500" height="300"></canvas>
                        </v-card>
                    </div>
                </v-container>

                <v-dialog v-model="dialogReport">
                    <v-card>
                        <v-card-text :height="$vuetify.breakpoint.height - 80">
                            <iframe
                                v-if="url"
                                :src="url"
                                width="100%"
                                height="100%"
                                style="background-color: #fff;"
                                frameborder="0"
                            />
                        </v-card-text>
                    </v-card>
                    <div>
                        <v-btn block color="primary" @click="dialogReport = false">
                            Fechar
                        </v-btn>
                    </div>
                </v-dialog>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Chart from 'chart.js';

export default {
    name: 'Vendas',

    components: { BaseContainer },

    data: () => ({
        dialogReport: false,
        loading: false,
        url: '',
        total: {
            hoje: 0,
            semana: 0,
            mes: 0,
            ano: 0,
        },
        periodo: {},
        vendas: [],
        monthNames: [
            'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
            'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
        ]
    }),

    mounted() {
        this.setLabels();
        this.carregarVendas();
    },

    methods: {
        carregarVendas() {
            this.$http.get('relatorio-vendas').then(resp => {
                this.total = resp.data.cards;
                this.gerarGrafico(resp.data.grafico);
            });
        },

        setLabels() {
            const hoje = this.moment().format('DD/MM/YYYY'),
                semanaFirst = this.moment().startOf('isoWeek').format('DD/MM/YYYY'),
                semanaLast = this.moment().endOf('isoWeek').format('DD/MM/YYYY'),
                mesFirst = this.moment().startOf('month').format('DD/MM/YYYY'),
                mesLast = this.moment().endOf('month').format('DD/MM/YYYY');

            this.periodo = {
                hoje,
                semana: `${semanaFirst} - ${semanaLast}`,
                mes: `${mesFirst} - ${mesLast}`,
            }
        },

        gerarGrafico(rows) {
            let today = new Date(),
                data = [],
                monthsLabel = [];

            for (var i = 5; i > -1; i--) {
                let d = new Date(today.getFullYear(), today.getMonth() - i, 1);
                let mes = this.moment(d).format('YYYY/MM');

                data.push(rows.find(r => r.mes == mes)?.total || 0);

                monthsLabel.push(this.monthNames[d.getMonth()]);
            }

            new Chart(document.getElementById('chart'), {
                type: 'bar',
                data: {
                    labels: monthsLabel,
                    datasets: [
                        {
                            label: 'Vendas Últimos 6 Meses',
                            data,
                            backgroundColor: [
                                'black',
                                'black',
                                'black',
                                'black',
                                'black',
                                'black'
                            ],
                        }
                    ]
                },
                options: {
                    // responsive: false,
                    // maintainAspectRatio: false,
                   scales: {
                        y: { beginAtZero: true },
                        yAxes: [
                            {
                                ticks: {
                                    callback: v => 'R$ ' + this.formatPrice(v)
                                },
                            }
                        ]
                    },
                    tooltips: {
                        callbacks: {
                            label: v => 'R$ ' + this.formatPrice(v.yLabel)
                        }
                    },
                }
            });
        },

        async gerarRelatorio(type) {
            if (this.loading) {
                return;
            }
            this.loading = true;
            try {
                let start;
                let end;
                if (type === 'day') {
                    start = this.moment().startOf('day').format('YYYY-MM-DD HH:mm');
                    end = this.moment().endOf('day').format('YYYY-MM-DD HH:mm');
                }
                if (type === 'week') {
                    start = this.moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm');
                    end = this.moment().endOf('isoWeek').format('YYYY-MM-DD HH:mm');
                }
                if (type === 'month') {
                    start = this.moment().startOf('month').format('YYYY-MM-DD HH:mm');
                    end = this.moment().endOf('month').format('YYYY-MM-DD HH:mm');
                }

                const params = new URLSearchParams();
                params.append('report', '\\App\\Reports\\VendasReport');
                params.append('start', start);
                params.append('end', end);

                const resp = await this.$gerarPdf(params);
                if (resp?.type === 'warning') {
                    this.notify(resp.msg, 'warning');
                }
            } finally {
                this.loading = false;
            }
        },

        vendasDetalhes(periodo) {
            this.$store.commit('setData', {periodo});
            this.$router.push('/vendas-detalhes');
        }
    }
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}
</style>
